import React, { useEffect, useState } from "react";
import FieldCard from "./FieldCard";
import chooseField_bg from "../../assets/Choosefield_bg.png";
import { useNavigate } from "react-router-dom";
import { getAllJobCategoriesCall } from "../../apis/job-categories/repo";
import { SUCCESS } from "../../utils/constants";
import worker from "../../assets/Worker.jpg";
import ClipLoader from "react-spinners/ClipLoader";

function ChooseField() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    jobCategories: [],
  });

  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    setIsLoading(true);
    getAllJobCategoriesCall(1, 100)
      .then(({ data }) => {
        if (data.error_code === SUCCESS) {
          setState((prevState) => ({ ...prevState, jobCategories: data.data }));
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  return (
    <div
    style={{
      backgroundImage: `url(${chooseField_bg})`,
      backgroundSize: "contain",
    }}
    className="w-full px-5 sm:px-10 md:px-16 lg:px-24 xxl:px-40 py-20"
    >
          <h1 className="text-2xl sm:text-3xl md:text-4xl xxl:text-5xl font-bold text-start text-white pb-16">Choose Your <span className="text-[#CEB551]">Field</span></h1>
    <div
      
      className="w-full grid grid-cols-12  gap-5 xxl:gap-10 justify-center min-[450px]:px-16 sm:px-0"
    >
      {isLoading ? (
        <div className="min-h-[40vh] col-span-12 text-center my-10 flex justify-center items-center ">
           <ClipLoader
            size={50} // Size of the loader
            color={"#C8AF4E"} // Color of the loader
            loading={isLoading}
          />
        </div>
      ) : (
        state.jobCategories.map((item, index) => (
          <FieldCard
            key={index}
            img={
              item.logo ? process.env.REACT_APP_URL.concat(item.logo) : worker
            }
            heading={item.name}
            content={item.description}
          />
        ))
      )}
    </div>
    </div>
  );
}

export default ChooseField;
