import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainScreen from "./screens/Main-screen";
import StartAppling from "./screens/StartAppling/StartAppling";
import Vacancies from "./screens/Vacancies/Vacancies";
import ApplyDirectly from "./screens/ApplyDirectly/ApplyDirectly";
import AboutUs from "./screens/AboutUs/AboutUs";
import ServiceHeader from "./components/Service.jsx/ServiceHeader";
import Services from "./screens/Services";
import RequestProposal from "./screens/RequestProposal";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ContactPage from "./screens/ContactPage";
function App() {
  useEffect(() => {
    AOS.init({ duration: 1000});
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainScreen />} />
          <Route path="/startappling" element={<StartAppling />} />
          <Route path="/vacancies" element={<Vacancies />} />
          <Route path="/apply-directly" element={<ApplyDirectly />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/proposal" element={<RequestProposal  />} />
          <Route path="/contact" element={<ContactPage  />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
