import React, { useState } from "react";
import StartAppling from "../../assets/startApplying_bg.png";
import texture_R from "../../assets/service_pattern.png";
import { FaArrowRightLong } from "react-icons/fa6";

import { useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";

function ApplyingHeader2() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const handleSearch = () => {
    navigate(`/vacancies?search=${encodeURIComponent(query)}`);
  };
  return (
    <>
      <div
        className="relative h-[70vh] lg:h-[99vh] bg-cover bg-center flex items-center justify-center "
        style={{ backgroundImage: `url(${StartAppling})` }}
      >
        <div className=" absolute inset-0 bg-black/75"></div>
        <img className="absolute right-1 top-0 h-full" src={texture_R} alt="" />
        <div className="w-full relative z-10 text-start text-whiter pt-20">
          <div className="pl-5 sm:pl-20 md:pl-32 lg:pl-40 flex flex-col gap-2 sm:gap-5 justify-center  items-start pt-10 sm:pt-0 pr-5">
            <div className="text-3xl sm:text-4xl md:text-5xl xxl:text-6xl  text-white relative">
              Get The Best Job <br />
              <span className="text-[#CEB551]  font-bold text-4xl sm:text-5xl md:text-6xl xxl:text-7xl">
                You Deserve
              </span>
              <p className="absolute featureGradientYellow w-24 sm:w-28 text-sm lg:w-36  text-center content-center -top-12 sm:-top-7 -left-5 sm:-left-14 -rotate-[20deg] h-10 rounded">
                Apply Here
              </p>
            </div>
            <p className="text-xs sm:text-sm xxl:text-base text-white">
              Tailored job matches to align with your skills and aspirations.{" "}
              <br className="hidden min-[380px]:block" /> Discover opportunities
              that fit your professional goals.
            </p>
            <div className="bg-white flex items-center rounded px-4 text-[#8C7934]">
              <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
                type="text"
                placeholder="Search Job titles, keywords"
                className="py-2 rounded outline-none w-40 sm:w-80 md:w-96 text-sm"
              />{" "}
              <IoSearch className="text-[#8C7934] text-xl" />
            </div>
            <div className="flex gap-3 sm:gap-7 flex-col sm:flex-row justify-start items-start">
              <button
                 onClick={handleSearch}
                className="text-white featureGradientYellow flex items-center gap-3 py-2 px-8 rounded text-xs sm:text-base"
              >
                Start <FaArrowRightLong />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyingHeader2;
