import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import homeL from "../../assets/home-L.jpg";
import homeR from "../../assets/home-R.jpg";
import textureL from "../../assets/texture_L.png";
import textureR from "../../assets/texture_R.png";
import { useNavigate } from "react-router-dom";

function Header2() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const photo_1 = {
    backgroundImage: `url(${homeL})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const photo_2 = {
    backgroundImage: `url(${homeR})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const texture_1 = {
    backgroundImage: `url(${textureL})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const texture_2 = {
    backgroundImage: `url(${textureR})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const navigate = useNavigate();

  const handleGetInTouch = () => {
    const getInTouchSection = document.getElementById("our__services");
    if (getInTouchSection) {
      const topOffset = getInTouchSection.offsetTop - 90;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };

  const handleGetInTouchTwo = () => {
    navigate("/startappling");
    const getInTouchSection = document.getElementById("industries");
    if (getInTouchSection) {
      const topOffset = getInTouchSection.offsetTop + 850;
      window.scrollTo({
        top: topOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="overflow-hidden  " id="home">
      <div className="h-20 md:h-16 featureGradientBlack bg-opacity-50"></div>
      <div className="w-full flex flex-col md:flex-row">
        <div
          style={photo_1}
          className="md:w-1/2 lg:h-[100vh] h-[55vh] md:h-[60vh]"
        >
          <div className="w-full h-full theme-bg_1 ">
          <div
            style={texture_1}
            className="h-[55vh] md:h-[60vh] lg:h-[100vh] text-white flex"
          >
           <div
              className="px-10 lg:px-0 min[450px]:w-[70%] md:w-full lg:w-[70%] m-auto items-center xl:ml-40 pt-10 md:pt-0"
              data-aos="fade-up"
            >
              <h1 className="text-3xl sm:text-4xl xxl:text-5xl font-bold opacity-100 px-2 py-4 sm:py-7 pr-8 relative text-start">
                We help candidates find their perfect job
                <p className="absolute featureGradientBlack w-28 text-sm lg:w-36 lg:text-base text-center content-center -top-5 md:-top-1  -left-8 lg:-left-14 -rotate-[20deg] h-10 rounded">
                  Job seeker
                </p>
              </h1>
              <p className="text-xs md:text-sm 2xl:text-lg opacity-100 px-2 text-start">
                Concise talent management solutions that matches with a
                particular industry, geography, or business requirement.
              </p>
              <div className="px-2 pt-5 sm:pt-10">
                <button
                  onClick={handleGetInTouchTwo}
                  class="flex items-center bg-white  border-gray-300 rounded shadow-md px-8 py-3 text-[15px] font-semibold text-gray-800  hover:bg-gray-200"
                >
                  <span>Start Applying</span>
                </button>
              </div>
            </div>
           </div>
          </div>
        </div>
        <div
          style={photo_2}
          className="md:w-1/2 lg:h-[100vh] h-[55vh] md:h-[60vh] "
        >
          <div
            style={texture_2}
            className=" lg:h-[100vh] text-white bg-black/75 h-[55vh] md:h-[60vh] flex"
          >
            <div
              className="px-10 lg:px-0 min[450px]:w-[70%] md:w-full lg:w-[70%] m-auto items-center pt-10 md:pt-0"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <h1 className="text-3xl sm:text-4xl xxl:text-5xl font-bold opacity-100 px-2 py-4 sm:py-7 relative text-start">
                We provide staffing Solution to our clients
                <p className="hidden md:block absolute featureGradientYellow w-28 lg:w-36 text-sm lg:text-base text-center content-center top-0 -right-5 xl:right-10  rotate-[25deg] h-10 rounded">
                  Companies
                </p>
                <p className="md:hidden block absolute featureGradientYellow w-28 text-sm lg:w-36 lg:text-base text-center content-center -top-5 md:-top-1  -left-8 lg:-left-14 -rotate-[20deg] h-10 rounded">
                  Companies
                </p>
              </h1>
              <p className="text-xs md:text-sm 2xl:text-lg opacity-100 px-2 pr-4 text-start">
                Concise talent management solutions that matches with a
                particular industry, geography, or business requirement.
              </p>
              <div className="px-2 pt-5 sm:pt-10">
                <button
                  onClick={()=>navigate('/services')}
                  class="flex items-center bg-white  border-gray-300 rounded shadow-md px-8 py-3 text-[15px] font-semibold text-gray-800  hover:bg-gray-200"
                >
                  <span>View Services</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header2;
