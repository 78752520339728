import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import outsourcing_img from "../../assets/outsourcing_img.png";
import { FaStar } from "react-icons/fa6";

function OutSourcing2() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
      delay: 100,
    });
  }, []);
  return (
    <div  id="about__Us" className="bg-[#1E1E1E] px-5 md:px-10 lg:px-20 flex py-24 sm:py-32 items-center flex-col lg:flex-row gap-5">
      <div data-aos="fade-up" className="w-[60%] lg:w-[45%] xl:pl-5 xxl:pl-10 lg:pr-5">
        <img src={outsourcing_img} alt="" />
      </div>
      <div data-aos="fade-up" className="w-90% lg:w-[55%] text-start pl-2.5 sm:pl-5 xl:pr-20 pt-5 lg:pt-0 ">
        <h1 className=" text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium pb-2">HR Simplified</h1>
        <div className="text-3xl sm:text-4xl xxl:text-5xl font-bold text-white">
          Outsourcing your HR needs has never been easier!
        </div>
        <div className="pl-5 sm:pl-10 border-l-2 border-[#CEB551] flex flex-col gap-5 my-10">
          <p className="text-white text-sm xxl:text-lg">
            AM Global is the leading provider of HR outsourcing services, and
            we provide a comprehensive suite of services that allow businesses
            to focus on their core competencies and leave the HR functions to
            us.
          </p>
          <p className="text-white text-sm xxl:text-lg">
            Partner with us for streamlined HR management, ensuring your
            business thrives without the administrative burden.
          </p>
        </div>
        <div className="w-full flex flex-col sm:flex-row gap-5">
            <div className="sm:w-60 xxl:w-80 flex gap-4">
                <div className="pt-2">
                <FaStar className="text-white"/>
                </div>
                <div>
                <div className="text-lg font-bold text-[#CEB551] pb-3 xxl:text-xl">Our Expertise</div>
                <div className=" text-white text-sm xxl:text-lg">Tailored HR solutions to match your business needs, ensuring efficiency and compliance.</div>
                </div>
            </div>
            <div className="sm:w-60 xxl:w-80 flex gap-4">
                <div className="pt-2">
                <FaStar className="text-white"/>
                </div>
                <div>
                <div className="text-lg font-bold text-[#CEB551] pb-3 xxl:text-xl">Global Reach</div>
                <div className=" text-white text-sm xxl:text-lg">Bringing global expertise with operations in the Gulf, India, Pakistan, and the USA.</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default OutSourcing2;
