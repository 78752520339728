import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/Logo.png";
import { FiAlignRight, FiX } from "react-icons/fi";
import Fade from "react-reveal/Fade";

export default function Navbar() {
  const [isScrolling, setIsScrolling] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
   const location = useLocation()
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolling(window.scrollY > 0);
      setToggleMenu(false);
    });
  }, []);

  const handleGetInTouch = () => {
    navigate('/contact')
  };
  const goTop = () =>  {
    window.scrollTo({
      top: '0px',
      behavior: "smooth",
    });
  }
  const navigate = useNavigate()
  return (
    <>
      <Fade top>
        <nav
          className={
            isScrolling
              ? "NavBar_Main_Container Active_Header"
              : "NavBar_Main_Container"
          }
          style={{ backgroundColor: isScrolling ? "#171717" : "#171717" }}
        >
          <div className="navbarInnerContainer">
            <div className="navbarLogoContainer">
              <img
                className="cursor-pointer"
                src={logo}
                alt="Logo"
                onClick={() => {
                  navigate('/')
                }}
              />
            </div>

            <div className="Navlink_Wrapper">
              <NavLink
                to="/"
                className="Link_Style"
                style={({ isActive }) => ({
                  color: isActive ? "#EDD569" : "white",
                })}
                onClick={goTop}
              >
                Home
              </NavLink>

              <NavLink
                to="/about-us"
                className="Link_Style"
                style={({ isActive }) => ({
                  color: isActive ? "#EDD569" : "white",
                })}
                onClick={goTop}
              >
                About us
              </NavLink>

              <NavLink
                to="/services"
                className="Link_Style"
                style={({ isActive }) => ({
                  color: isActive ? "#EDD569" : "white",
                })}
                onClick={goTop}
              >
                Our Services
              </NavLink>

              <NavLink
              to={location.pathname === '/' ? '' : '/' }
                onClick={() => {
                  const whyUsSection = document.getElementById("why_us");
                  if (whyUsSection) {
                    whyUsSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="Link_Style"
                style={({ isActive }) => ({
                  // color: isActive ? "#EDD569" : "white",
                })}
              >
                Why us
              </NavLink>

              <NavLink
              to={location.pathname === '/' ? '' : '/' }
                onClick={() => {
                  const industriesSection =
                    document.getElementById("industries");
                  if (industriesSection) {
                    industriesSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="Link_Style"
                style={({ isActive }) => ({
                  // color: isActive ? "#EDD569" : "white",
                })}
              >
                Industries
              </NavLink>
            </div>

            <div onClick={handleGetInTouch} className="NavbarButtonContainer">
              Contact us
            </div>

            <div className="collapse_button_number">
              <div
                onClick={handleGetInTouch}
                className="NavbarButtonContainer small_screen"
              >
                Contact us
              </div>

              <div className={"NavLink_App_Smaller_Devices"}>
                {toggleMenu ? (
                  <div className="Toggle_Btn_CLose">
                    <FiX
                      color="white"
                      size={30}
                      onClick={() => setToggleMenu(false)}
                    />
                  </div>
                ) : (
                  <div className="Toggle_Btn_Open">
                    <FiAlignRight
                      color="white"
                      size={30}
                      onClick={() => setToggleMenu(true)}
                    />
                  </div>
                )}

                {toggleMenu && (
                  <div className={"NavLink_App_Smaller_Overlay"}>
                    <div
                      className={"Navlink_App_Links_Wrapper_SmallScreen_links"}
                    >
                      <NavLink
                        to="/"
                        className="Link_Style"
                        style={({ isActive }) => ({
                          color: isActive ? "#EDD569" : "white",
                        })}
                        onClick={goTop}
                      >
                        Home
                      </NavLink>

                      <NavLink
                        to="/about-us"
                        className="Link_Style"
                        style={({ isActive }) => ({
                          color: isActive ? "#EDD569" : "white",
                        })}
                        onClick={goTop}
                      >
                        About us
                      </NavLink>

                      <NavLink
                        to="/services"
                        className="Link_Style"
                        style={({ isActive }) => ({
                          color: isActive ? "#EDD569" : "white",
                        })}
                        onClick={goTop}
                      >
                        Our Services
                      </NavLink>

                      <NavLink
                      to={location.pathname === '/' ? '' : '/' }
                        onClick={() => {
                          const whyUsSection =
                            document.getElementById("why_us");
                          if (whyUsSection) {
                            whyUsSection.scrollIntoView({ behavior: "smooth" });
                          }
                        }}
                        className="Link_Style"
                        style={({ isActive }) => ({
                          // color: isActive ? "#EDD569" : "white",
                        })}
                      >
                        Why us
                      </NavLink>

                      <NavLink
                      to={location.pathname === '/' ? '' : '/' }
                        onClick={() => {
                          const industriesSection =
                            document.getElementById("industries");
                          if (industriesSection) {
                            industriesSection.scrollIntoView({
                              behavior: "smooth",
                            });
                          }
                        }}
                        className="Link_Style"
                        style={({ isActive }) => ({
                          // color: isActive ? "#EDD569" : "white",
                        })}
                      >
                        Industries
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </Fade>
    </>
  );
}
