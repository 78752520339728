import React from "react";
import { IoMdAddCircle } from "react-icons/io";
import job_detail_header_bg from "../../assets/job_detail_header_bg.png";
import { Link } from 'react-router-dom';

function JobDetailHead() {
  return (
    <div className="pt-16">
      <div
        className="relative h-[50vh] sm:h-[70vh] lg:h-[80vh] bg-cover bg-center flex items-center justify-center"
        style={{
          backgroundImage: `url(${job_detail_header_bg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="w-full relative z-10 text-start text-whiter">
          <div className="sm:pl-20 lg:pl-28 flex flex-col gap-2 sm:gap-10 justify-center pt-10 sm:pt-0">
            <div className="text-2xl sm:text-3xl md:text-4xl xxl:text-5xl font-bold text-white text-center sm:text-start">
              <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium pb-2">
                Submit Your CV
              </h1>
              Upload your resume & apply <br /> for your
              <span className="text-[#CEB551]"> ideal job </span> easily.
            </div>
            <div className="flex justify-start">
              <Link
                to="#cv_form"
                className="featureGradientYellow flex gap-3 items-center rounded-md px-5 py-2"
              >
                Upload CV <IoMdAddCircle className="text-2xl" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDetailHead;
