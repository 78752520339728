import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function WhyAmGlobal() {
  useEffect(() => {
    AOS.init({ duration: 600, once:true});
  }, []);
  return (
    <div className="w-full bg-[#1E1E1E] px-10 md:px-16 lg:px-24 xxl:px-40 py-20">
      <div>
        {/* <h1 className=" text-[#CEB551] text-lg md:text-3xl xxl:text-4xl text-center lg:text-start">
          Why AM Global
        </h1> */}
        <div className="text-3xl sm:text-3xl xxl:text-4xl font-semibold text-white pt-1 lg:pt-2 text-center pb-2">
        Why AM Global
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-x-5 lg:gap-x-10 gap-y-5 text-start pt-12">
         <div className="w-full featureGradientYellow px-2 h-14 content-center col-span-12 md:col-span-6 relative rounded-full pl-16 text-xs sm:text-[13px] lg:text-sm text-white shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div className="w-[4.4rem] h-[4.4rem] rounded-full featureGradientBlack absolute -left-4 -top-2 text-[#CEB551] font-bold text-xl flex justify-center items-center shadow-lg">
                01
            </div>
             Dedicated supervisors to ensure optimal experience 
         </div>
         <div className="w-full featureGradientBlack px-2 h-14 content-center col-span-12 md:col-span-6 relative rounded-full pl-16 text-xs sm:text-[13px] lg:text-sm text-white shadow-lg" data-aos="fade-up" data-aos-duration="700">
            <div className="w-[4.4rem] h-[4.4rem] rounded-full featureGradientBlack absolute -left-4 -top-2 text-[#CEB551] font-bold text-xl flex justify-center items-center shadow-lg">
                02
            </div>
            Best pay master 
         </div>
         <div className="w-full featureGradientBlack px-2 h-14 content-center col-span-12 md:col-span-6 relative rounded-full pl-16 text-xs sm:text-[13px] lg:text-sm text-white shadow-lg" data-aos="fade-up" data-aos-duration="900">
            <div className="w-[4.4rem] h-[4.4rem] rounded-full featureGradientBlack absolute -left-4 -top-2 text-[#CEB551] font-bold text-xl flex justify-center items-center shadow-lg">
                03
            </div>
            Best in class benefits and incentives  
         </div>
         <div className="w-full featureGradientBlack px-2 h-14 content-center col-span-12 md:col-span-6 relative rounded-full pl-16 text-xs sm:text-[13px] lg:text-sm text-white shadow-lg" data-aos="fade-up" data-aos-duration="1100">
            <div className="w-[4.4rem] h-[4.4rem] rounded-full featureGradientBlack absolute -left-4 -top-2 text-[#CEB551] font-bold text-xl flex justify-center items-center shadow-lg">
                04
            </div>
            High quality staff accommodation 
         </div>
         <div className="w-full featureGradientBlack px-2 h-14 content-center col-span-12 md:col-span-6 relative rounded-full pl-16 text-xs sm:text-[13px] lg:text-sm text-white shadow-lg" data-aos="fade-up" data-aos-duration="1300">
            <div className="w-[4.4rem] h-[4.4rem] rounded-full featureGradientBlack absolute -left-4 -top-2 text-[#CEB551] font-bold text-xl flex justify-center items-center shadow-lg">
                05
            </div>
            ⁠Free of cost transportation 
         </div>
         <div className="w-full featureGradientYellow px-2 h-14 content-center col-span-12 md:col-span-6 relative rounded-full pl-16 text-xs sm:text-[13px] lg:text-sm text-white shadow-lg" data-aos="fade-up" data-aos-duration="1500">
            <div className="w-[4.4rem] h-[4.4rem] rounded-full featureGradientBlack absolute -left-4 -top-2 text-[#CEB551] font-bold text-xl flex justify-center items-center shadow-lg">
                06
            </div>
            Diverse pool of clients and industries to match your career aspirations
         </div>
      </div>
    </div>
  );
}

export default WhyAmGlobal;
