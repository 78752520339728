import React, { useEffect } from "react";
import ourService_icon1 from "../../assets/ourDervices_icon1.png";
import ourService_icon2 from "../../assets/ourDervices_icon2.png";
import ourService_icon3 from "../../assets/ourDervices_icon3.png";
import ourService_icon4 from "../../assets/ourDervices_icon4.png";
import ourService_icon5 from "../../assets/ourDervices_icon5.png";
import ourService_icon6 from "../../assets/ourDervices_icon6.png";
import core_service_img from '../../assets/Core_services_img.png'
import AOS from "aos";
import "aos/dist/aos.css";
function OurCoreServices() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div
      id="our__services"
      className="w-full bg-[#212121] text-start text-white  lg:pl-24 px-5 sm:px-10 md:px-20 py-28"
    >
      <div className="flex  items-center lg:items-end flex-col lg:flex-row lg:justify-start gap-5 lg:gap-10">
      <div>
        <h1 className=" text-[#CEB551] text-2xl md:text-3xl xxl:text-4xl font-medium text-center lg:text-start">
          Services
        </h1>
        <div className="text-3xl sm:text-4xl xxl:text-5xl font-bold text-white pt-1 lg:pt-3 text-center lg:text-start">
          Our Core Services
        </div>
      </div>
      <div className="lg:pl-10 lg:border-l-2 border-[#CEB551] text-center lg:text-start text-lg xxl:text-xl">
      Explore our tailored HR, staffing, and payroll solutions <br  className="hidden sm:block"/> designed for efficiency and global reach.
      </div>
      </div>

      <div className="flex lg:items-end xxl:justify-start flex-col lg:flex-row gap-5 pt-14 justify-center items-center">
        <div className="w-[50%] sm:w-[40%] md:w-[25%] lg:w-[35%] xl:w-[25%]  xxl:w-[20%]">
           <img className="h-full" src={core_service_img} alt="" />
        </div>
      <div className="w-full lg:w-[70%] grid grid-cols-12 mt-16 gap-4">
        <div
          data-aos-duration="500"
          data-aos="zoom-in"
          className="col-span-12 sm:col-span-6 md:col-span-4  flex gap-2 flex-col featureGradientYellow shadow-md rounded-xl px-5 py-5 "
        >
          <div>
            <img className="w-9" src={ourService_icon1} alt="" />
          </div>
          <h2 className="text-lg xxl:text-xl font-bold">
            Manpower  Supply
          </h2>
          <p className="text-[12px] xxl:text-base ">
            We provide a comprehensive range of manpower solutions to help
            businesses stay staffed and productive.
          </p>
        </div>
        <div
          data-aos-duration="600"
          data-aos="zoom-in"
          className="col-span-12 sm:col-span-6 md:col-span-4  flex gap-2 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5  "
        >
          <div>
            <img className="w-8" src={ourService_icon2} alt="" />
          </div>
          <h2 className="text-lg xxl:text-xl font-bold">
            PEO  Services
          </h2>
          <p className="text-[12px] xxl:text-base ">
            Our PEO services can help businesses save time and money while
            reducing compliance risks.
          </p>
        </div>
        <div
          data-aos-duration="700"
          data-aos="zoom-in"
          className="col-span-12 sm:col-span-6 md:col-span-4  flex gap-2 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5 "
        >
          <div>
            <img className="w-8" src={ourService_icon3} alt="" />
          </div>
          <h2 className="text-lg xxl:text-xl font-bold">
            HR  Outsourcing
          </h2>
          <p className="text-[12px] xxl:text-base ">
            Our team of HR professionals can take on all your HR needs, from
            payroll to benefits administration.
          </p>
        </div>
        <div
          data-aos-duration="800"
          data-aos="zoom-in"
          className="col-span-12 sm:col-span-6 md:col-span-4  flex gap-2 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5 "
        >
          <div>
            <img className="w-8" src={ourService_icon4} alt="" />
          </div>
          <h2 className="text-lg xxl:text-xl font-bold">
            Recruitment  Services
          </h2>
          <p className="text-[12px] xxl:text-base ">
            Let our experts help you find the best talent for your open
            positions to keep your business moving forward.
          </p>
        </div>
        <div
          data-aos-duration="900"
          data-aos="zoom-in"
          className="col-span-12 sm:col-span-6 md:col-span-4  flex gap-2 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5 "
        >
          <div>
            <img className="w-8" src={ourService_icon5} alt="" />
          </div>
          <h2 className="text-lg xxl:text-xl font-bold">
          Payroll Servie
          </h2>
          <p className="text-[12px] xxl:text-base ">
          We provide WPS-compliant payroll solutions, saving you time and costs while ensuring efficiency.
          </p>
        </div>
        <div
          data-aos-duration="1000"
          data-aos="zoom-in"
          className="col-span-12 sm:col-span-6 md:col-span-4  flex gap-2 flex-col featureGradientYellow shadow-md rounded-xl px-5 py-5 "
        >
          <div>
            <img className="w-8" src={ourService_icon6} alt="" />
          </div>
          <h2 className="text-lg xxl:text-xl font-bold">
          IT Staffing
          </h2>
          <p className="text-[12px] xxl:text-base ">
          We offer expert IT staffing solutions across the Gulf, India, Pakistan, and the USA, with tailored, project based support.
          </p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default OurCoreServices;
