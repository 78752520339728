import React, { useEffect } from 'react';
import image_jafza from '../assets/jafza.jpg';
import image_dubai from '../assets/dubai.jpg';
import texture from '../assets/ourOffice_bg.png'; // Import your texture image
import AOS from "aos";
import "aos/dist/aos.css";
function OurOffice() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const images = [
    { src: image_jafza, address: 'Jafza One, Tower B Office No:1102', heading: 'JAFZA Office' },
    { src: image_dubai, address: 'Office number: 1605 & 1606, Al Reem Tower - Al Maktoum Rd - Deira - Al Buteen - Dubai', heading: 'Head Office' },
  ];

  return (
    <div id='OurOffice' className="relative bg-[#292929] py-10 px-4">
      {/* Textured Background */}
      <div className="absolute right-0 opacity-30">
        <img className='w-40 h-60' src={texture} alt="" />
      </div>
      <div className="absolute left-0 bottom-0 opacity-30 rotate-180">
        <img className='w-40 h-60' src={texture} alt="" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <h2 className="text-white text-4xl font-bold text-center pt-10">Our Office</h2>
        <p className="text-gray-300 text-center pt-2 mb-10 text-sm">Find us at our offices in JAFZA and Dubai Main Office</p>
        <div className="flex flex-col md:flex-row md:space-x-10 lg:space-x-40 space-y-6 md:space-y-0 justify-center" data-aos="zoom-out">
          {images.map((image, index) => (
            <div key={index} className="relative group w-full md:w-96 h-64 md:h-80 rounded-lg border">
              <img
                src={image.src}
                alt={`Office ${index + 1}`}
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105 rounded-lg border"
              />
              <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center transition-opacity duration-500 scale-105 rounded-lg border">
                <span className="text-white text-xl font-semibold px-4 text-center">{image.heading}</span>
                <span className="text-white text-base font-semibold px-4 text-center mt-2">{image.address}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurOffice;
