import React, { useEffect } from "react";
import findJob_1 from '../../assets/findJob_1.png'
import findJob_2 from '../../assets/findJob_2.png'
import findJob_bg from "../../assets/findJob_bg.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
function FindJob() {
  useEffect(() => {
    AOS.init({ duration: 1000});
  }, []);
  const navigate = useNavigate()
  return (
    <div
    style={{ backgroundImage: `url(${findJob_bg})`,
backgroundSize : 'cover' }}
    className="w-full px-5 sm:px-10 md:px-16 lg:px-24 xxl:px-40 flex flex-col-reverse md:flex-row items-center py-16">
      <div className="w-full md:w-1/2 text-white text-start  md:pr-10 pt-10 md:pt-0" data-aos="fade-right">
        <h1 className="text-2xl sm:text-3xl md:text-4xl xxl:text-5xl font-bold ">
          We Find <br />
          <span className="text-[#CEB551]">The Job For You</span>
        </h1>
        <p className="text-sm xxl:text-lg py-5">With AM Global you can outsource your entire HR function or any specific HR services that you may need. We will work with you to develop a customized solution that meets your specific business needs with ease.</p>
        <div className="flex gap-3 sm:gap-5 flex-col  justify-start items-start">
        <p className="text-sm xxl:text-lg">Sign up as a job seeker and put us to work.</p>
            <button
            onClick={()=>navigate('/vacancies')}
            className="text-white featureGradientYellow flex items-center gap-3 py-1.5 px-5 rounded text-xs sm:text-base"> Find Jobs
            </button>
          </div>
      </div>
      <div className="w-full min-[450px]:w-[90%] sm:w-[80%] md:w-1/2 flex gap-5 justify-center" data-aos="fade-left">
        <img className="w-[45%] min-[450px]:w-1/2 h-[300px] md:h-auto" src={findJob_1} alt="" />
        <img className="w-[45%] min-[450px]:w-1/2 h-[300px] md:h-auto" src={findJob_2} alt="" />
      </div>
    </div>
  );
}

export default FindJob;
