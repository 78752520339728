import React from "react";
import service_header_bg from "../../assets/service_header_bg.png";
import texture_R from "../../assets/service_pattern.png";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function ServiceHeader() {
  const navigate = useNavigate() 
  return (
    <>
      <div
        className="relative h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[99vh] bg-cover bg-center flex items-center justify-center "
        style={{ backgroundImage: `url(${service_header_bg})` }}
      >
        <div className=" absolute inset-0 bg-black/75"></div>
        <img
          className="absolute right-1 top-0 h-full"
          src={texture_R}
          alt=""
        />
        <div className="w-full relative z-10 text-start text-whiter">
          <div className="pl-10 sm:pl-20 md:pl-32 lg:pl-40 flex flex-col gap-2 sm:gap-10 justify-center pt-10 sm:pt-0">
            <h1 className="txt-lg sm:text-xl ont-semibold text-[#CEB551] xxl:text-2xl">
              Home {">"} Services
            </h1>
            <div className="text-2xl sm:text-3xl md:text-4xl xxl:text-5xl font-bold text-white">
              Global HR & <br /> Manpower{" "}
              <span className="text-[#CEB551]">Expertise</span>
            </div>
          <div className="flex gap-3 sm:gap-7 flex-col sm:flex-row justify-start items-start">
            <button
            onClick={()=>navigate('/proposal')}
            className="text-white featureGradientYellow flex items-center gap-3 py-1.5 px-3 rounded text-xs sm:text-base">Request Proposal <FaAngleRight />
            </button>
            {/* <button className="text-black bg-white flex items-center gap-3 py-1.5 px-3 rounded text-xs sm:text-base">Register CV <FaAngleRight />
            </button> */}
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceHeader;
