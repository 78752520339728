import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import proposal_bg from '../assets/subscribe_bg.png';
import proposal_img from "../assets/proposal_img.png";
import Footer from "../components/Footer";
import { submitRequestProposalCall } from "../apis/contact-form/repo"
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import the necessary styles

function RequestProposal() {

  useEffect(() => {
    window.scrollTo({
      top: '0px',
      behavior: "smooth",
    });
  }, [])
  
  // State for all form inputs
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessEmail: "",
    phoneNumber: "",
    countryCode: "+966",
    companyName: "",
    jobTitle: "",
    payrollOutsourcing: "",
    payrollHeadcount: "",
    outsourcingCountry: "",
    agree: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isViewValid()) {
      const object = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        business_email: formData.businessEmail,
        phone_no: `${formData.countryCode}${formData.phoneNumber}`,
        company_name: formData.companyName,
        your_title: formData.jobTitle,
        payroll_outsourcing: formData.payrollOutsourcing,
        payroll_headcount: formData.payrollHeadcount,
        payroll_services: formData.outsourcingCountry,
      };

      setIsLoading(true);
      submitRequestProposalCall(object)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code === 0) {
            setFormData({
              firstName: "",
              lastName: "",
              businessEmail: "",
              phoneNumber: "",
              companyName: "",
              jobTitle: "",
              payrollOutsourcing: "",
              payrollHeadcount: "",
              outsourcingCountry: "",
              agree: false,
            });
            alert(data.message);
          }
        })
        .catch((err) => {
          console.error("Error:", err);
          setIsLoading(false);
        });
    }
  };
  function isInvalidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email);
  }
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handlePhoneChange = (value, country, e) => {
    setFormData({
      ...formData,
      countryCode: `+${country.dialCode}`,
      phoneNumber: value.replace(country.dialCode, ""),
    });
  };

  function isViewValid() {
    if (!formData.firstName) {
      alert("Please enter your first name");
    } else if (!formData.lastName) {
      alert("Please enter your last name");
    } else if (!formData.businessEmail) {
      alert("Please enter your business email");
    } else if (isInvalidEmail(formData.businessEmail)) {
      alert("Please enter a valid email");
    } else if (!formData.phoneNumber) {
      alert("Please enter your phone number");
    } else if (!formData.companyName) {
      alert("Please enter your company name");
    } else if (!formData.jobTitle) {
      alert("Please enter your job title");
    } else if (!formData.payrollOutsourcing) {
      alert("Please enter payroll outsourcing details");
    } else if (!formData.payrollHeadcount) {
      alert("Please enter your payroll headcount");
    } else if (!formData.outsourcingCountry) {
      alert(
        "Please enter the country you want to cover for payroll outsourcing"
      );
    } else if (!formData.agree) {
      alert("You must agree to the terms and conditions");
    } else {
      return true;
    }
    return false;
  }

  return (
    <div className="bg-[#212121] min-h-[100vh]">
      <Navbar />
      <img
        className="absolute z-10 top-[50%] w-full"
        src={proposal_bg}
        alt=""
      />
      <div className="py-32 text-white relative z-20">
        <h1 className="text-2xl sm:text-4xl xxl:text-5xl font-bold pt-5">
          Request Proposal
        </h1>
        <p className="pt-5 text-sm xxl:text-lg">
          Share details of the role you’re looking to fill and we’ll <br /> be
          in touch within 24 hours.
        </p>
        <div className="w-[90%] sm:w-[80%] mx-auto text-start flex justify-center items-center pt-20">
          <div className="featureGradientBlack rounded-lg shadow-lg p-8 w-full grid lg:grid-cols-2 gap-8">
            {/* Left Side - Form */}
            <div className="text-white space-y-6">
              <h2 className="text-3xl font-bold">Let's Connect</h2>
              <p className="text-gray-400 text-xs">
                Have questions or need support? Contact us today to <br />{" "}
                discuss your HR needs. Our team is here to help.
              </p>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                  />
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                    className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                  />
                </div>
                <input
                  type="email"
                  name="businessEmail"
                  value={formData.businessEmail}
                  onChange={handleInputChange}
                  placeholder="Business Email"
                  className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                />
                <PhoneInput
                  country={"ae"}
                  value={formData.countryCode + formData.phoneNumber}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    backgroundColor: "#333333",
                    color: "white",
                    borderRadius: "6px",
                    padding: "10px 50px",
                    border: "1px solid #525252",
                    width : '100%'
                  }}
                  buttonStyle={{
                    backgroundColor: "#333333",
                    borderRadius: "6px",
                    border: "1px solid #525252",
                  }}
                />
                <div className="grid sm:grid-cols-2 gap-4">
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    placeholder="Company Name"
                    className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                  />
                  <input
                    type="text"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleInputChange}
                    placeholder="Your Job Title"
                    className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                  />
                </div>
                <input
                  type="text"
                  name="payrollOutsourcing"
                  value={formData.payrollOutsourcing}
                  onChange={handleInputChange}
                  placeholder="Payroll Outsourcing"
                  className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                />
                <input
                  type="text"
                  name="payrollHeadcount"
                  value={formData.payrollHeadcount}
                  onChange={handleInputChange}
                  placeholder="What is your payroll headcount?"
                  className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                />
                <input
                  type="text"
                  name="outsourcingCountry"
                  value={formData.outsourcingCountry}
                  onChange={handleInputChange}
                  placeholder="Which Country do you want to cover for Payroll Outsourcing?"
                  className="px-3 py-2.5 bg-[#333333] border border-[#525252] text-white rounded-md w-full text-[12px] shadow focus:outline-none"
                />
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="agree"
                    checked={formData.agree}
                    onChange={handleInputChange}
                    className="form-checkbox text-blue-600 h-5 w-5"
                  />
                  <span className="ml-2 text-white text-sm">
                    I agree to the{" "}
                    <a href="" className="underline text-[#CEB551]">
                      terms
                    </a>{" "}
                    and{" "}
                    <a href="" className="underline text-[#CEB551]">
                      conditions
                    </a>
                  </span>
                </label>
                <div className="w-full flex justify-start">
                  <button
                    type="submit"
                    disabled={isLoading || !formData.agree}
                    className={`${
                      formData.agree ? "featureGradientYellow" : "bg-slate-700"
                    }   text-white font-bold py-2 px-8 rounded-full`}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>

            {/* Right Side - Image */}
            <div className="hidden lg:block">
              <img src={proposal_img} alt="Proposal" className="rounded-lg" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RequestProposal;
