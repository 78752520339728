import React, { useEffect, useState } from "react";
import feature_texture from "../../assets/feature_texture.png";
import "../ContactUS/ContactUs.css";
import { submitContactFormCall } from "../../apis/contact-form/repo";
import { SUCCESS } from "../../utils/constants";
import { isInvalidEmail, isOnlyNumber } from "../../utils/validations";
import { isNullOrEmpty } from "../../utils/text-utils";
import AOS from "aos";
import "aos/dist/aos.css";

function ServiceContact() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const [state, setState] = useState({
    name: "",
    companyName: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  function isViewValid() {
    if (!state.name) alert("Please enter first name");
    else if (!state.companyName) alert("Please enter company name");
    else if (!state.email) alert("Please enter email");
    else if (isInvalidEmail(state.email)) alert("Please enter valid email");
    else if (!state.mobile) alert("Please enter mobile number");
    else if (!state.message) alert("Please enter your query.");
    else return true;
    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isViewValid()) {
      let object = {
        name: state.name,
        companyName: state.companyName,
        email: state.email,
        mobile: state.mobile,
        message: state.message,
        subject : state.subject
      };
      setIsLoading(true);
      submitContactFormCall(object)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == SUCCESS)
            setState({
              name: "",
              companyName: "",
              email: "",
              mobile: "",
              subject: "",
              message: "",
            });
          alert(data.message);
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="w-full bg-[#222223] relative" 
    id="contact_us"
    
    >
      <div
        style={{
          background: `url(${feature_texture})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: 0.3,
        }}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="px-5 md:px-10 lg:px-20 flex pt-16 sm:pt-28 pb-2 sm:pb-5  items-center flex-col lg:flex-row gap-5 relative z-10">
        <div
        //   data-aos="fade-up"
          className="w-full lg:w-[50%] text-start pl-2.5 sm:pl-5 xl:pr-20 pt-5 lg:pt-0"
        >
          <h1 className="text-lg font-bold text-[#CEB551] xxl:text-xl text-center lg:text-start">
          Contact Us
          </h1>
          <div className="text-2xl sm:text-4xl xxl:text-5xl font-bold text-white pt-1 lg:pt-3 text-center lg:text-start" >
          How Can We Help?
          </div>
          <div className="lg:pl-3 sm:pl-10 lg:border-l-2 border-[#CEB551] flex flex-col gap-5 my-6 lg:my-10">
            <p className="text-white text-xs sm:text-sm xxl:text-xl text-center lg:text-start">
            If there is anything you'd like to know about what we do, how we do it, or how we can help you, please get in touch.
            </p>
          </div>
        </div>
        <div
          // data-aos="fade-left"
          className="w-[100%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:pl-5 xxl:pl-10 lg:pr-5"
        >
          <div className="w-full m-auto bg-[#171717] rounded-lg">
            <form className="form p-[18px] md:p-[40px]" onSubmit={handleSubmit}>
              <p className="title">Get in Touch</p>
              <div className="flex flex-col gap-3.5 sm:flex-row">
                <label className="w-full sm:w-1/2">
                  <input
                    className="input1"
                    type="text"
                    name="name"
                    placeholder=""
                    value={state.name}
                    onChange={handleChange}
                    required
                  />
                  <span>Name</span>
                </label>

                <label className="w-full sm:w-1/2">
                  <input
                    className="input1"
                    type="text"
                    name="companyName"
                    placeholder=""
                    value={state.companyName}
                    onChange={handleChange}
                    required
                  />
                  <span>Company Name</span>
                </label>
              </div>
              <div className="flex flex-col gap-3.5  sm:flex-row">
                <label className="w-full sm:w-1/2">
                  <input
                    className="input1"
                    type="email"
                    name="email"
                    placeholder=""
                    value={state.email}
                    onChange={handleChange}
                    required
                  />
                  <span>Email</span>
                </label>
                <label className="w-full sm:w-1/2">
                  <input
                    className="input1"
                    type="tel"
                    name="mobile"
                    placeholder=""
                    value={state.mobile}
                    onChange={(e) => {
                      if (
                        isOnlyNumber(e.target.value) ||
                        isNullOrEmpty(e.target.value)
                      )
                        setState((prevState) => ({
                          ...prevState,
                          mobile: e.target.value,
                        }));
                    }}
                    required
                  />
                  <span>Mobile</span>
                </label>
              </div>
              <label>
                <input
                  className="input1"
                  type="text"
                  name="subject"
                  placeholder=""
                  value={state.subject}
                  onChange={handleChange}
                  required
                />
                <span>Subject</span>
              </label>
              <label>
                <textarea
                  style={{ resize: "none" }}
                  className="input1"
                  name="message"
                  placeholder=""
                  value={state.message}
                  onChange={handleChange}
                  required
                  cols={30}
                  rows={5}
                />
                <span>How can we help you</span>
              </label>
              <div className="w-full flex justify-end">
                <button className="submit" disabled={isLoading}>
                  {isLoading ? "Processing..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceContact;
