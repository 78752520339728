import React, { useEffect } from "react";
import secondScreenHeaderImg from "../../assets/startApplingHeaderImage.png";
import policy from "../../assets/Application.png";
import messageIcon from "../../assets/messageIcon.png";
import suitcaseImg from "../../assets/suitcaseImg copy.png";
import directionImage from "../../assets/directionImage.png";
import recycleImg from "../../assets/recycleImg.png";
import rightCircle from "../../assets/right_circle.png";
import leftCircle from "../../assets/left_circle.png";
import LoadingSVG from "../../components/LoadingSVG";
import AOS from "aos";
import "aos/dist/aos.css";
function HowItWork() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="w-full py-36 relative">
      <h3 className="text-white pb-14 text-2xl md:text-3xl lg:text-4xl xxl:text-5xl font-semibold ">
        How it Works
      </h3>
      <img
        className="absolute top-24 sm:top-14  w-20 sm:w-36 right-0"
        src={rightCircle}
        alt=""
      />
      <img
        className="absolute top-24 sm:top-14  w-20 sm:w-36 left-0"
        src={leftCircle}
        alt=""
      />
      <div className="imagesMainContainer text-white" data-aos="flip-up">
        <div className="imagesMainContainerChild">
          <img className="w-10 sm:w-12 lg:w-14" src={policy} alt="" />

          <div className="text-[7px] sm:text-sm md:text-base">Application</div>
        </div>
        <div className=" hidden md:block">
          <LoadingSVG />
        </div>
        <div className="imagesMainContainerChild">
          <img className="w-10 sm:w-12 lg:w-14" src={messageIcon} alt="" />
          <div className="text-[7px] sm:text-sm md:text-base">Conversation</div>
        </div>
        <div className=" hidden md:block">
          <LoadingSVG />
        </div>
        <div className="imagesMainContainerChild">
          <img className="w-10 sm:w-12 lg:w-14" src={suitcaseImg} alt="" />

          <div className="text-[7px] sm:text-sm md:text-base">Your job</div>
        </div>
        <div className=" hidden md:block">
          <LoadingSVG />
        </div>
        <div className="imagesMainContainerChild">
          <img className="w-10 sm:w-12 lg:w-14" src={directionImage} alt="" />

          <div className="text-[7px] sm:text-sm md:text-base">Guidance</div>
        </div>
        <div className=" hidden md:block">
          <LoadingSVG />
        </div>
        <div className="imagesMainContainerChild">
          <img className="w-10 sm:w-12 lg:w-14" src={recycleImg} alt="" />

          <div className="text-[7px] sm:text-sm md:text-base">Continued</div>
        </div>
      </div>
    </div>
  );
}

export default HowItWork;
