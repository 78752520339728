import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Outsourcing from "../components/Outsourcing";
import ChooseUs from "../components/ChooseUs";
import Impact from "../components/Impact";
import Footer from "../components/Footer";
import ServicesUpdate from "../components/ServicesUpdate";
import ImageSlider from "../components/ImageSlider";
import ContactUs from "../components/ContactUS/ContactUs";
import OurOffice from "../components/OurOffice";
import Header2 from "../components/NewLandingPage/Header2";
import Feature2 from "../components/NewLandingPage/Feature2";
import OutSourcing2 from "../components/NewLandingPage/OutSourcing2";
import OurServices from "../components/NewLandingPage/OurServices";
import WhyChooseUs from "../components/NewLandingPage/WhyChooseUs";
import ServiceContact from "../components/Service.jsx/ServiceContact";

export default function MainScreen() {
  window.scrollTo({
    top: '0px',
    behavior: "smooth",
  });
  return (
    <div>
      <Navbar />
      <Header2 />
      <Feature2 />
      <OutSourcing2 />
      <OurServices />
      <WhyChooseUs />
      <OurOffice />
      <ChooseUs />
      <Impact />
      <ServiceContact />
      <Footer />
    </div>
  );
}
