import { useState } from "react";

export default function SimpleDropdown({ handleDateChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('All');

  const options = [
    { label: "All", value: 1 },
    { label: "Last 24 Hours", value: 2 },
    { label: "Last 7 Days", value: 3 },
    { label: "Last 14 Days", value: 4 },
    { label: "Last 30 Days", value: 5 },
  ];

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    
    // Call the parent component's handler to manage the date filter
    handleDateChange(option.value);
  };

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center space-x-2 text-sm text-[#888888]"
      >
        <span className="flex gap-2">
          <span>Sort: </span>{selectedOption}
        </span>
        <svg
          className="w-4 h-4 text-gray-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 featureGradientBlack border border-gray-300 rounded-md shadow-lg z-10">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className="cursor-pointer px-4 py-2 text-sm text-white hover:bg-[#888888] rounded-lg"
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
