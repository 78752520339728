import React, { useEffect } from 'react'
import amLife_1 from '../../assets/amLife_1.png'
import amLife_2 from '../../assets/amLife_2.png'
import amLife_3 from '../../assets/amLife_3.png'
import amLife_4 from '../../assets/amLife_4.png'
import amLife_5 from '../../assets/amLife_5.png'
import amLife_6 from '../../assets/amLife_6.png'
import AOS from "aos";
import "aos/dist/aos.css";
function LifeAtAmGlobal() {
  useEffect(() => {
    AOS.init({ duration: 1000});
  }, []);
  return (
    <div className="bg-[#1e1e1e] w-full px-5 sm:px-10 md:px-16 lg:px-24 xxl:px-40 py-20">
        <div className="text-3xl sm:text-4xl xxl:text-5xl font-semibold text-white pt-1 lg:pt-2 text-center  pb-16 w-full">
        Life at AM Global
        </div>
        <div className='w-full flex gap-3'>
            <div className='w-1/2 flex flex-col gap-3'>
                  <div className='flex gap-3'>
                    <div className='w-1/2' data-aos="">
                    <img src={amLife_1} alt="" />
                    </div>
                    <div className='w-1/2' data-aos="">
                    <img src={amLife_2} alt="" />
                    </div>
                  </div>
                  <div className='' data-aos="">
                  <img src={amLife_5} alt="" />
                  </div>
            </div>
            <div className='w-1/2 flex flex-col gap-3'>
                  <div className='' data-aos="">
                  <img src={amLife_6} alt="" />
                  </div>
                  <div className='flex gap-3' data-aos="">
                    <div className='w-1/2'>
                    <img src={amLife_4} alt="" />
                    </div>
                    <div className='w-1/2' data-aos="">
                    <img src={amLife_3} alt="" />
                    </div>
                  </div>
            </div>
        </div>
    </div>
  )
}

export default LifeAtAmGlobal