import React, { useEffect } from "react";
import ServiceContact from "../components/Service.jsx/ServiceContact";
import Navbar from "../components/Navbar";

function ContactPage() {
  useEffect(() => {
    window.scrollTo({
      top: '0px',
      behavior: "smooth",
    });
  }, [])
  return (
    <>
      <Navbar />
      <ServiceContact />
    </>
  );
}

export default ContactPage;
