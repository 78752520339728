 import React from 'react'
 
 function LoadingSVG() {
   return (
     <svg xmlns="http://www.w3.org/2000/svg" width="60" height="4" class="dashed__svg" viewBox="0 0 88 4" >
        <line id="Line_10" data-name="Line 10" x2="84" transform="translate(2 2)" fill="none" stroke="#CEB551" stroke-linecap="round" stroke-width="4" stroke-dasharray="4 10"></line>
     </svg>
   )
 }
 
 export default LoadingSVG