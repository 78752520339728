import React, { useEffect, useState } from "react";
import NavbarNext from "../../components/NavbarNext";
import Footer from "../../components/Footer";
import "./StartAppling.css";
import { CiSearch } from "react-icons/ci";
import { FaArrowRight } from "react-icons/fa6";
import secondScreenHeaderImg from "../../assets/startApplingHeaderImage.png";
import policy from "../../assets/policy.png";
import messageIcon from "../../assets/messageIcon.png";
import suitcaseImg from "../../assets/suitcaseImg.png";
import directionImage from "../../assets/directionImage.png";
import recycleImg from "../../assets/recycleImg.png";
import loadingImg from "../../assets/loadingImg.png";
import Card from "../../components/card/Card";
import Navbar from "../../components/Navbar";
import AboutUsNavbar from "../../components/AboutUsNavbar";
import { useNavigate } from "react-router-dom";
import Apply from "../../components/Vacancies/Apply";
import ApplyBackground from "../../assets/Vacancies//pattern.png";
import start_apply_bg from "../../assets/start_apply_right_bg.png";
import AOS from "aos";
import "aos/dist/aos.css";
import LoadingSVG from "../../components/LoadingSVG";
import developer from "../../assets/Developer.jpg";
import worker from "../../assets/Worker.jpg";
import sale from "../../assets/sales.jpg";
import manufacturing from "../../assets/Manufacturing.jpg";
import finance from "../../assets/Finance.jpg";
import IT from "../../assets/IT.jpg";
import { getAllJobCategoriesCall } from "../../apis/job-categories/repo";
import { SUCCESS } from "../../utils/constants";
import ApplyingHeader2 from "../../components/NewApplying/ApplyingHeader2";
import ChooseField from "../../components/NewApplying/ChooseField";
import FindJob from "../../components/NewApplying/FindJob";
import WhyAmGlobal from "../../components/NewApplying/WhyAmGlobal";
import LifeAtAmGlobal from "../../components/NewApplying/LifeAtAmGlobal";
import Subscribe from "../../components/NewApplying/Subscribe";
import HowItWork from "../../components/NewApplying/HowItWork";
// import ExploreCategory from "../../components/ExploreCategory/ExploreCategory";
// import PaymentPlan from "../../components/PaymentPlan/PaymentPlan";

export default function StartAppling() {
  const navigate = useNavigate();
  const client_names = [
    "Alice Johnson",
    "Bob Smith",
    "Catherine Lee",
    "David Martinez",
    "Eva Green",
    "Frank Wright",
    "Grace Kim",
    "Henry Clark",
    "Isabel Turner",
    "Jackie Harris",
    "Catherine Lee",
    "David Martinez",
    "Eva Green",
    "Frank Wright",
    "Grace Kim",
    "Henry Clark",
    "Isabel Turner",
    "Jackie Harris",
    "Catherine Lee",
    "David Martinez",
    "Eva Green",
    "Frank Wright",
    "Grace Kim",
    "Henry Clark",
    "Isabel Turner",
    "Jackie Harris",
    "Bob Smith",
    "Catherine Lee",
  ];
  const [state, setState] = useState({
    jobCategories: [],
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
      delay: 100,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  function getCategories() {
    getAllJobCategoriesCall(1, 100)
      .then(({ data }) => {
        if (data.error_code == SUCCESS) {
          setState((prevState) => ({ ...prevState, jobCategories: data.data }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  return (
    <div className="bg-[#212121]">
      <Navbar />
      
       <ApplyingHeader2 />
       <ChooseField />
       <HowItWork /> 
       <FindJob />
       <WhyAmGlobal />
       <LifeAtAmGlobal />
       <Subscribe />
      <Footer />
    </div>
  );
}
