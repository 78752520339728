import React, { useEffect, useState } from 'react';
import rectangle from '../../assets/subscibe_rectangle.png';
import subsribe_bg from '../../assets/subscribe_bg.png';
import AOS from "aos";
import "aos/dist/aos.css";
import { subscribeAPiCall } from '../../apis/contact-form/repo';

function Subscribe() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const subscribeFunction = () => {
    setError('');
    setSuccessMessage('');
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setLoading(true);

    subscribeAPiCall({ 'email': email })
      .then(({ data }) => {
        setLoading(false);
        if (data.error_code === 0) {
          setSuccessMessage('Subscribed successfully!');
          setEmail('');  // Clear the input after success
        } else {
          setError('Failed to subscribe, please try again.');
        }
      })
      .catch(() => {
        setLoading(false);
        setError('An error occurred. Please try again.');
      });
  };

  return (
    <div
      style={{ background: `url(${subsribe_bg})`, backgroundSize: 'cover' }}
      className="w-full py-32"
    >
      <div className="signUpMainContainer group border border-[#EDD569] relative" data-aos="zoom-out-up">
        <img className="absolute left-0 rotate-180 w-12 sm:w-20" src={rectangle} alt="" />
        <img className="absolute right-0 w-12 sm:w-20" src={rectangle} alt="" />
        <h1 style={{ color: 'white', marginTop: '0px' }} className="text-base md:text-xl lg:text-2xl md:pb-5 mb-5 font-semibold">
          Subscribe Our Newsletter For <br />
          Newest Updates
        </h1>
        <div className="inputMainContainerChild">
          <input
            className="inputFieldSignUpContainer w-[70%] sm:w-[60%]"
            placeholder="Enter your email address here"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Email input"
          />
          <button
            onClick={subscribeFunction}
            className="signUpButton bg-[#212121] rounded-lg relative -left-2"
            disabled={loading}
          >
            {loading ? 'Subscribing...' : 'Subscribe'}
          </button>
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        {successMessage && <p className="text-green-900 mt-2">{successMessage}</p>}
      </div>
    </div>
  );
}

export default Subscribe;
