import React, { useEffect } from "react";
import feature_texture from "../../assets/feature_texture.png";
import whatSetUsApart from "../../assets/whatSetsUSApart.png";
import AOS from "aos";
import "aos/dist/aos.css";

function WhatSetsUsApart() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="w-full bg-[#222223] relative">
      <div
        style={{
          background: `url(${feature_texture})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          opacity: 0.3,
        }}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="px-5 md:px-10 lg:px-20 flex pt-16 sm:pt-28 pb-2 sm:pb-5  items-center flex-col-reverse lg:flex-row gap-5 relative z-10">
        <div
          // data-aos="fade-up"
          className="w-full lg:w-[55%] text-start pl-2.5 sm:pl-5 pr-5 pt-5 lg:pt-0"
        >
          <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium text-center lg:text-start">
            What Sets Us Apart
          </h1>
          <div className="text-3xl sm:text-4xl xxl:text-5xl font-bold text-white pt-3 lg:text-start text-center">
            Leading the Way in HR Solutions and Global{" "}
            <span className="text-[#CEB551]">Expertise</span>
          </div>
          <div className="pl-5 sm:pl-10 border-l-2 border-[#CEB551] flex flex-col gap-5 my-14">
            <p className="text-white text-xs sm:text-sm xxl:text-xl">
              At AM Global, we distinguish ourselves by delivering customized HR
              solutions that cater to your specific business needs. With our
              extensive experience across the Gulf, India, Pakistan, and the
              USA, we ensure your company excels with the right talent,
              efficient payroll management, and strategic HR services.
            </p>
            <p className="text-white text-xs sm:text-sm xxl:text-xl">
              AM Global excels in providing customized HR solutions across
              global markets, ensuring your business runs efficiently with the
              right talent and streamlined services.
            </p>
          </div>
        </div>
        <div
          // data-aos="fade-up"
          className="w-[70%] lg:w-[45%] xl:pl-5 xxl:pl-10 lg:pr-5"
        >
          <img
            className="w-[80%] lg:w-[90%] mx-auto lg:mx-0"
            src={whatSetUsApart}
            alt="What Sets Us Apart"
          />
        </div>
      </div>
    </div>
  );
}

export default WhatSetsUsApart;
