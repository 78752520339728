import React, { useEffect } from "react";
import chooseUs_img from "../../assets/chooseUs-img.png";
import AOS from "aos";
import "aos/dist/aos.css";
function WhyChooseUs() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div id="why_us" className="w-full bg-[#1E1E1E] text-start px-5 sm:px-10 md:px-16 lg:px-20 xl:px-24 py-20">
      <div data-aos="zoom-in-up" className="w-full md:pl-10">
        <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium">Why Choose AM Global</h1>
        <div className=" flex flex-col md:flex-row">
          <div className="w-[90%] md:w-1/2 flex items-end">
            <p className="text-2xl md:text-3xl lg:text-4xl xxl:text-5xl font-bold text-white pr-3 content-end">
              Trusted HR Solutions <br /> Tailored for Your{" "}
              <span className="text-[#CEB551]">Success</span>
            </p>
          </div>
          <div className="pl-5 lg:pl-10 w-[90%] md:w-1/2 border-l text-sm xxl:text-lg  content-center text-white mt-5 md:mt-0">
            AM Global has a proven track record of providing high-quality HR
            outsourcing and manpower supply solutions to businesses of all
            sizes. We are the leading provider of HR outsourcing services in the
            UAE and provide top-notch Manpower Supply, HR Outsourcing, and
            Recruitment Services to our clients.
          </div>
        </div>
      </div>
      <div data-aos="zoom-in-up" className="w-full flex flex-col md:flex-row gap-9 pt-16 md:pt-28 justify-center items-center">
        <div className="w-[90%] md:w-1/2">
          <img src={chooseUs_img} alt="" />
        </div>
        <div className="w-full md:w-1/2">
          <div className="text-2xl md:text-3xl lg:text-4xl xxl:text-5xl font-bold text-white">
            Find the Right Solution for  Your{" "}
            <span className="text-[#CEB551]">Business Today!</span>
          </div>
          <div className="pl-5 lg:pl-10 border-l-2 border-[#CEB551] flex flex-col gap-5 my-10">
            <p className="text-white text-sm xxl:text-lg">
              AM Global is one of the top outsourcing agencies that offer
              cutting-edge solutions for all your HR requirements. A M Global
              covering all the seven emirates and with plans of expansion to the
              GCC. We have experienced work force deployed across various
              industry segments and an employee pool with a mix of
              nationalities. 
            </p>
            <p className="text-white text-sm xxl:text-lg">
              By providing exemplary services we aim to focus on providing value
              to both to our clients and employees. This has helped us gain and
              maintain good business relationship with our existing clients and
              we hope to continue doing so in the future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
