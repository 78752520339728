import React from "react";
import ServiceHeader from "../components/Service.jsx/ServiceHeader";
import WhatSetsUsApart from "../components/Service.jsx/WhatSetsUsApart";
import OurCoreServices from "../components/Service.jsx/OurCoreServices";
import Impact from "../components/Impact";
import ServiceContact from "../components/Service.jsx/ServiceContact";
import Navbar from "../components/Navbar";
import AboutUsNavbar from "../components/AboutUsNavbar";

function Services() {
  window.scrollTo({
    top: '0px',
    behavior: "smooth",
  });
  return (
    <>
      <Navbar />
      <ServiceHeader />
      <WhatSetsUsApart />
      <OurCoreServices />
      <Impact />
      <ServiceContact />
    </>
  );
}

export default Services;
