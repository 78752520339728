import React from "react";
import service_header_bg from "../../assets/service_header_bg.png";
import texture_R from "../../assets/service_pattern.png";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import job_bg from '../../assets/job_bg.png'
function JobsHeader() {
  const navigate = useNavigate() 
  return (
    <div className="pt-16">
      <div
        className="relative h-[50vh] sm:h-[60vh] md:h-[60vh] lg:h-[70vh] bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: `url(${job_bg})` ,backgroundSize : 'cover' }}
      >
        <div className="w-full relative z-10 text-start text-whiter">
          <div className=" sm:pl-20 lg:pl-28 flex flex-col gap-2 sm:gap-10 justify-center pt-10 sm:pt-0">
            <div className="text-2xl sm:text-3xl md:text-4xl xxl:text-5xl font-bold text-white text-center sm:text-start">
            Match Your Skills to <br /> the 
              <span className="text-[#CEB551]"> Ideal Role</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobsHeader