import React, { useEffect } from "react";
import feature_texture from "../../assets/subscribe_bg.png";
import location from "../../assets/location.png";
import skills from "../../assets/skills.png";
import range from "../../assets/range.png";
import workingHour from "../../assets/workingHour.png";
import jobType from "../../assets/jobType.png";
import experience from "../../assets/experience.png";
import ReactHtmlParser from "react-html-parser";
import AOS from "aos";
import "aos/dist/aos.css";

function JobDescription(data) {
  console.log(data);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="w-full bg-[#222223] relative">
        <div
          style={{
            background: `url(${feature_texture})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            opacity: 0.3,
          }}
          className="absolute top-0 left-0 w-full h-full"
        />

        <div className="px-5 md:px-10 lg:px-20 flex pt-16 sm:pt-28 pb-2 sm:pb-5  md:items-start items-center flex-col-reverse lg:flex-row gap-5 relative z-10">
          <div
            // data-aos="fade-up"
            className="w-full lg:w-[60%] text-start pl-2.5 sm:pl-5 pr-5 pt-5 lg:pt-0"
          >
            <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium text-center lg:text-start">
              More About the Role
            </h1>
            <div className="text-3xl sm:text-4xl xxl:text-5xl font-bold text-white pt-3 lg:text-start text-center">
              Company Overview
            </div>
            <div className="pl-5 sm:pl-10 border-l-2 border-[#CEB551] flex flex-col gap-5 my-14">
              <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-bold text-center lg:text-start">
                About Company :
              </h1>
              <div
                className="text-white text-sm sm:text-base xxl:text-xl"
                  dangerouslySetInnerHTML={{ __html: data.data?.about_company }}
              />
            </div>
          </div>
          <div className="w-[100%] lg:w-[40%] xl:pl-5 xxl:pl-10 lg:pr-5 flex justify-center">
            <div className="featureGradientBlack w-[100%] lg:w-[80%] p-10 pr-3 rounded-2xl flex flex-col gap-7 border border-gray-600">
              <div className="flex text-start gap-5">
                <img className="w-6 h-6" src={jobType} alt="" />
                <p>{data.data?.job_type?.title}</p>
              </div>
              <div className="flex text-start gap-5">
                <img className="w-6 h-6" src={experience} alt="" />
                <p>{data.data?.experience}</p>
              </div>
              <div className="flex text-start gap-5">
                <img className="w-6 h-7" src={location} alt="" />
                <p>{data.data?.address}</p>
              </div>
              <div className="flex text-start gap-5">
                <img className="w-6 h-6" src={workingHour} alt="" />
                <p>{data.data?.working_hours} hours </p>
              </div>
              <div className="flex text-start gap-5">
                <img className="w-6 h-6" src={skills} alt="" />
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  {data.data?.skill_id?.map((items) => (
                    <li key={items._id}>{items.title}</li>
                  ))}
                </ul>
              </div>
              <div className="flex text-start gap-5">
                <img className="w-6 h-6" src={range} alt="" />
                <p>
                  {data.data?.min_salary}$ - {data.data?.max_salary}$
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-10 lg:px-20 flex py-16   md:items-start items-center flex-col-reverse lg:flex-row gap-5 relative z-10 bg-[#1E1E1E]">
        <div
          // data-aos="fade-up"
          className="w-full text-start pl-2.5 sm:pl-5 pr-5 pt-5 lg:pt-0"
        >
          <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium text-center lg:text-start">
            Description
          </h1>
          <div className="text-3xl sm:text-4xl xxl:text-5xl font-bold text-white pt-3 lg:text-start text-center">
            Job Description
          </div>
          <div className="pl-5 sm:pl-10 border-l-2 border-[#CEB551] flex flex-col gap-5 my-14">
            <div
              className="text-white text-sm sm:text-base xxl:text-xl"
              dangerouslySetInnerHTML={{ __html: data.data?.job_description }}
            />
          </div>
        </div>
      </div>
      <div className="px-5 md:px-10 lg:px-20 flex py-16   md:items-start items-center flex-col-reverse lg:flex-row gap-5 relative z-10">
        <div
          // data-aos="fade-up"
          className="w-full text-start pl-2.5 sm:pl-5 pr-5 pt-5 lg:pt-0"
        >
          <h1 className="text-[#CEB551] text-xl md:text-2xl xxl:text-3xl font-medium text-center lg:text-start">
            Requirement
          </h1>
          <div className="text-3xl sm:text-4xl xxl:text-5xl font-bold text-white pt-3 lg:text-start text-center">
            Job Requirement
          </div>
          <div className="pl-5 sm:pl-10 border-l-2 border-[#CEB551] flex flex-col gap-5 my-14">
            <div
              className="text-white text-sm sm:text-base xxl:text-xl"
              dangerouslySetInnerHTML={{ __html: data.data?.job_requirement }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default JobDescription;
