import React, { useEffect } from "react";
import feature_1 from "../../assets/feature_1.png";
import feature_2 from "../../assets/feature_2.png";
import feature_3 from "../../assets/feature_3.png";
import feature_texture from "../../assets/feature_texture.png";
import feature_circle from "../../assets/feature_circle.png";
import AOS from "aos";
import "aos/dist/aos.css";
function Feature2() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="w-full bg-[#222223] relative">
      <div
        style={{
          background: `url(${feature_texture})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // backgroundPositionY: '-100px',
        opacity: 0.3, 
        }}
        className="absolute top-0 left-0 w-full h-full bg-cover"
      />
        <img className="w-52 sm:w-72 md:w-80 absolute right-0" src={feature_circle} alt="" />
      <div data-aos="fade-up" className="relative z-10 p-6 py-20">
        <h1 className="text-[#CEB551]  text-2xl md:text-3xl xxl:text-4xl font-medium pb-2">Features</h1>
        <h2 className="text-3xl sm:text-4xl xxl:text-5xl font-semibold text-white">
          Why Partner With Us
        </h2>
        <div className="w-full pt-32 gap-10 flex flex-wrap justify-center">
          <div className="w-[290px] h-[320px] 2xl:w-[350px] 2xl:h-[400px] featureGradientBlack rounded-xl shadow-md text-start text-white px-5 border border-neutral-600 relative">
            <div className="w-24 h-24 rounded-full featureGradientYellow -top-10 absolute flex justify-center items-center">
                <img className="w-14" src={feature_1} alt="" />
            </div>
            <div className="text-2xl font-bold mt-16">
              Tailored <br />
              HR Solutions
              <hr className="w-32 my-5"/>
            </div>
              <p className="text-sm pt-2 sm:pt-4">
                Provide customized HR and staffing solutions, meeting the unique
                needs of diverse industries and business sizes.
              </p>
          </div>
          <div className="w-[290px] h-[320px] 2xl:w-[350px] 2xl:h-[400px] featureGradientYellow rounded-xl shadow-md text-start text-white px-5 border border-neutral-600 relative">
            <div className="w-24 h-24 rounded-full bg-white -top-10 absolute flex justify-center items-center">
                <img className="w-14" src={feature_2} alt="" />
            </div>
            <div className="text-2xl font-bold mt-16">
            Global <br /> Expertise
              <hr className="w-28 my-5"/>
            </div>
              <p className="text-sm pt-2 sm:pt-4">
              Leverage extensive experience across Gulf countries, India, Pakistan, and the USA to deliver exceptional results.
              </p>
          </div>
          <div className="w-[290px] h-[320px] 2xl:w-[350px] 2xl:h-[400px] featureGradientBlack rounded-xl shadow-md text-start text-white px-5 border border-neutral-600 relative">
            <div className="w-24 h-24 rounded-full featureGradientYellow -top-10 absolute flex justify-center items-center">
                <img className="w-14" src={feature_3} alt="" />
            </div>
            <div className="text-2xl font-bold mt-16">
            Efficiency <br />
            & Compliance
              <hr className="w-36 my-5"/>
            </div>
              <p className="text-sm pt-2 sm:pt-4">
              Streamline processes with WPS-compliant payroll and HR services, reducing costs and ensuring regulatory adherence.
              </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature2;
