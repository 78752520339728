import React, { useEffect } from 'react'
import ourService_icon1 from '../../assets/ourDervices_icon1.png'
import ourService_icon2 from '../../assets/ourDervices_icon2.png'
import ourService_icon3 from '../../assets/ourDervices_icon3.png'
import ourService_icon4 from '../../assets/ourDervices_icon4.png'
import ourService_icon5 from '../../assets/ourDervices_icon5.png'
import ourService_icon6 from '../../assets/ourDervices_icon6.png'
import AOS from "aos";
import "aos/dist/aos.css";
function OurServices() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);
  return (
    <div id="our__services" className='w-full bg-[#212121] text-start text-white p-10 sm:p-16 lg:p-24 py-20'>
         <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold'>Our Services</h1>
         <p className='text-sm pt-7'>Concise talent management solutions that matches with a <br /> particular industry, geography, or business requirement.</p>

         <div  className='w-full flex md:justify-between flex-col md:flex-row mt-16 gap-4 md:gap-0 justify-center'>
                <div data-aos-duration="500"  data-aos="zoom-in" className='w-full sm:w-[90%] md:w-[24%] flex gap-3 flex-col featureGradientYellow shadow-md rounded-xl px-5 py-5 '>
                    <div>
                        <img className='w-14' src={ourService_icon1} alt="" />
                    </div>
                    <h2 className='text-2xl font-bold'>Manpower <br /> Supply
                    </h2>
                    <p className='text-xs md:text-sm xxl:text-lg pb-5'>We provide a comprehensive range of manpower solutions to help businesses stay staffed and productive.
                    </p>
                </div>
                <div data-aos-duration="600"  data-aos="zoom-in" className='w-full sm:w-[90%] md:w-[24%] flex gap-3 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5  '>
                    <div>
                        <img className='w-12' src={ourService_icon2} alt="" />
                    </div>
                    <h2 className='text-2xl font-bold'>PEO <br /> Services
                    </h2>
                    <p className='text-xs md:text-sm xxl:text-lg pb-5'>Our PEO services can help businesses save time and money while reducing compliance risks.
                    </p>
                </div>
                <div data-aos-duration="700"  data-aos="zoom-in" className='w-full sm:w-[90%] md:w-[24%] flex gap-3 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5 '>
                    <div>
                        <img className='w-12' src={ourService_icon3} alt="" />
                    </div>
                    <h2 className='text-2xl font-bold'>HR <br /> Outsourcing 
                    </h2>
                    <p className='text-xs md:text-sm xxl:text-lg pb-5'>Our team of HR professionals can take on all your HR needs, from payroll to benefits administration.
                    </p>
                </div>
                <div data-aos-duration="800"  data-aos="zoom-in" className='w-full sm:w-[90%] md:w-[24%] flex gap-3 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5 '>
                    <div>
                        <img className='w-12' src={ourService_icon4} alt="" />
                    </div>
                    <h2 className='text-2xl font-bold'>Recruitment <br /> Services
                    </h2>
                    <p className='text-xs md:text-sm xxl:text-lg pb-5'>Let our experts help you find the best talent for your open positions to keep your business moving forward.
                    </p>
                </div>
         </div>
         <div className='w-full flex md:justify-between flex-col md:flex-row mt-4 gap-4 justify-center '>
                <div data-aos-duration="900"  data-aos="zoom-in" className='w-full sm:w-[90%] md:w-[49%] flex gap-3 flex-col featureGradientBlack shadow-md rounded-xl px-5 py-5 '>
                    <div>
                        <img className='w-12' src={ourService_icon5} alt="" />
                    </div>
                    <h2 className='text-2xl font-bold'>IT Staffing
                    </h2>
                    <p className='text-xs md:text-sm xxl:text-lg pb-5'>Our highly efficient & experienced team provides IT Staffing solutions to companies across Gulf countries, India, Pakistan and USA. Our IT Staff Augmentation team has successfully delivered both short-term and long-term solutions as well as project-based deployment and we provide our customers a concise talent management solutions that matches with a particular industry, geography, or business requirement.
                    </p>
                </div>
                <div data-aos-duration="1000"  data-aos="zoom-in" className='w-full sm:w-[90%] md:w-[49%] flex gap-3 flex-col featureGradientYellow shadow-md rounded-xl px-5 py-5'>
                    <div>
                        <img className='w-12' src={ourService_icon6} alt="" />
                    </div>
                    <h2 className='text-2xl font-bold'>Payroll Service
                    </h2>
                    <p className='text-xs md:text-sm xxl:text-lg pb-5'>Managing payroll, accounting, and all other financial responsibilities in house and at once is time-consuming and prone to errors. We work in partnership with our clients to develop, implement and manage bespoke payroll services. Our services are in line with the WPS and our clients enjoy considerable cost savings and can focus on core activities of the business. 
                    </p>
                </div>
         </div>
    </div>
  )
}

export default OurServices