import React, { useEffect, useState } from "react";
import l from "../../assets/fieldCard.png";
import { FaArrowRightLong } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";

function FieldCard({ img, heading, content }) {
  useEffect(() => {
    AOS.init({ duration: 600});
  }, []);

  const [isExpanded, setIsExpanded] = useState(false); // State to track if the text is expanded

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev); // Toggle the expanded state
  };

  return (
    <div className="w-full col-span-12 sm:col-span-6 lg:col-span-4 border border-[#C8AF4E] rounded-lg bg-[#1B1B1B]">
      <div className="h-[200px] w-full">
        <img
          className="w-full h-full rounded-t-lg object-cover"
          src={img}
          alt="Field Card"
        />
      </div>
      <div className="w-full text-start p-4 md:p-6 bg-[#1B1B1B] rounded-b-lg">
        <div className="flex flex-col gap-2" data-aos="fade-up">
          <h1 className="text-[#C8AF4E] text-2xl font-semibold">{heading}</h1>

          <p
            className={`text-xs md:text-sm text-white ${
              isExpanded ? "" : "line-clamp-3"
            }`}
          >
            {content}
          </p>

          <div
            className="text-white flex items-center gap-3 hover:scale-y-105 cursor-pointer"
            onClick={toggleReadMore}
          >
            {isExpanded ? "Show Less" : "Read More"} <FaArrowRightLong />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldCard;
