import "./Loading.css";

export default function Loader({ style }) {
  return (
    <div
      className="model__container__bigger"
      style={{ justifyContent: "center", zIndex: "9999999", position: 'fixed',height:'100vh' }}
    >
      <div className="loading-spinner-bigger"></div>
    </div>
  );
}
