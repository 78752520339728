import axios from "axios";

export async function submitContactFormCall(data) {
    let route = process.env.REACT_APP_URL.concat(
        "contactForm/submitForm",
    );
    // console.log(`${route} REQUEST`);
    return axios.post(route, data, {
        ["axios-retry"]: {
            retries: 0,
        },
    });
}
export async function submitRequestProposalCall(data) {
    let route = process.env.REACT_APP_URL.concat(
        "proposal-form/submitForm",
    );
    // console.log(`${route} REQUEST`);
    return axios.post(route, data, {
        ["axios-retry"]: {
            retries: 0,
        },
    });
}

export async function subscribeAPiCall(data) {
   let route = process.env.REACT_APP_URL.concat(
    'subscribe/sendEmail',
   );
   return axios.post (route, data ,{
    ['axios-retry'] : {
        retries  : 0 
    }
   })
}

